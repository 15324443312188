:root {
  // Unimicro Bridge theme
  --primary-h: 175;
  --primary-s: 82%;
  --primary-l: 39%;

  --success-rgb: 5, 150, 105;
  --danger-rgb: 230, 34, 34;
  --warning-rgb: 217, 119, 6;
  --info-rgb: 2, 132, 199;

  --primary: hsl(var(--primary-h) var(--primary-s) var(--primary-l));
  --success: rgb(var(--success-rgb));
  --danger: rgb(var(--danger-rgb));
  --warning: rgb(var(--warning-rgb));
  --info: rgb(var(--info-rgb));

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-sans-serif: "Inter", sans-serif;
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  --font-family-base: var(--font-sans-serif);
  --font-family-code: var(--font-monospace);
  // --gradient: #{$gradient};

  --font-size-root: 16px;
  --font-size-base: 0.875rem;
  --line-height-base: 1.42;

  --body-font-family: var(--font-family-base);
  --body-color: var(--text-color-2);
  --body-bg: #F2F2F2;

  --code-color: #D63384;

  --pre-color: var(--body-color);

  --border-radius: 0.375rem;
  --border-radius-sm: 0.1875rem;
  --border-radius-lg: 0.5rem;

  --inverted-color: #FFFFFF;
  --inverted-bg: #374151;

  --kbd-color: var(--inverted-color);
  --kbd-bg: var(--inverted-bg);
  --kbd-border-radius: var(--border-radius);

  --link-color: #06B6D4;
  --link-hover-color: #06B6D4;

  --text-color-1: #000000;
  --text-color-2: #374151;
  --text-color-3: #6B7280;
  --text-muted: #ACACAC;
  --text-danger: var(--danger);

  --border-color: #E5E7EB;

  --powered-by-color: var(--text-color-3);

  --mark-bg: #FCF8E3;

  --layout-logo-width: 15.916875rem;
  --layout-logo-height: 2.25rem;
}

@supports (font-variation-settings: normal) {
  :root {
    --font-sans-serif: "Inter var", sans-serif;
  }
}

@import "elevation";

@import "alert";
@import "applied-filter-badge";
@import "badge";
@import "button";
@import "card";
@import "connections";
@import "dashboard";
@import "dropdown";
@import "filter";
@import "flow-steps";
@import "form";
@import "heading";
@import "page-heading";
@import "huge-icon";
@import "layout-login";
@import "list-group";
@import "modal";
@import "nav";
@import "notifications-popover";
@import "onboarding";
@import "page-heading";
@import "invoice-report-status";
@import "order-event-report-status";
@import "product-sync-report-status";
@import "payout-transaction-report-status";
@import "payout-summary-bookkeeping-report-status";
@import "progress";
@import "table";
@import "tags-input";
@import "toasts";
@import "tooltip";
@import "user-profile-dropdown";
