//
// Labels
//

.form-label {
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--form-label-color);

  &[for] {
    cursor: pointer;
  }
}
