:root {
  --connections-item-border-color: var(--border-color);
  --connections-image-border-color: rgba(112, 112, 112, 0.2);

  --new-connection-filter-input-border: #E0E0E0;
  --new-connection-filter-input-placeholder-color: var(--text-color-3);

  --add-new-connection-btn-bg: #FFFFFF;
  --add-new-connection-btn-color: var(--primary);
  --add-new-connection-btn-border: var(--primary);

  --add-new-connection-btn-hover-bg: #FFFFFF;
  --add-new-connection-btn-hover-color: #0D9488;
  --add-new-connection-btn-hover-border: #0D9488;

  --add-new-connection-btn-disabled-bg: #F3F4F6;
  --add-new-connection-btn-disabled-color: #9CA3AF;
  --add-new-connection-btn-disabled-border: #9CA3AF;

  --connection-active-icon-color: var(--primary);

  --connection-broken-text-color: #B31B1B;
  --connection-broken-icon-color: #E62222;
  
  --connection-misconfigured-text-color: #B35009;
  --connection-misconfigured-icon-color: #F2A50C;
}
